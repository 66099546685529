// Here you can add other styles
.c-app:not(.c-legacy-theme):not(.c-dark-theme) .btn:not([class*=ghost]):not([class*=link]):not([class*=outline]):not([class*=transparent]) {
    border: 0;
}

div[class*="-menu"] {
    z-index: 999;
}

@media (min-width: 992px) {
    .modal-xxl {
        max-width: 1200px;
    }
}

@media (min-width: 992px) {
    .modal-fullscreen {
        max-width: 100%;
        margin: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        min-height: 100vh;
        display: flex;
    }
}

.min-height-100 {
    min-height: 100px;
}

.nc-thumbnail {
    min-height: 100px;
}

.nc-thumbnail-300 {
    min-height: 100px;
    max-height: 300px;
}

.nc-thumbnail-200 {
    min-height: 200px;
    max-height: 200px;
    object-fit: cover;
}

.b-r-10 {
    border-width: thick !important;
}

@media (min-width: 992px) {
    .nc-thumbnail {
        min-height: 200px;
    }

    .nc-thumbnail-300 {
        min-height: 100px;
        max-height: 300px;
    }

    .nc-thumbnail-200 {
        min-height: 200px;
        max-height: 200px;
    }

    .nc-thumbnail-100 {
        min-height: 100px;
        max-height: 100px;
    }
}

// start: own CSS
.chatDate {
    text-align: center;
    padding-bottom: 0.5rem;
}

.centerCol {
    max-height: 100%;
}

.events {
    height: 30%;
}

.chatArea {
    height: 100%;
}

.message {
    padding: 0.5rem;
}

.container-fluid {
    height: 100%;
}

/*
.fade {
  height: 100%;
}
 */
.h100 {
    height: 100%;
}

.h90 {
    height: calc(100% - 24px) !important;
}

.profileview {
    display: flex;
    flex-direction: column;
}

.fg1 {
    flex-grow: 1;
}

.comment {
    height: calc(100% - 0px) !important;
}

.msg_container_send {
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 25px;
    padding: 10px;
    position: relative;
    margin-right: 10px;
    margin-left: 10px;
    background-color: #78e08f;
}

.mod_message {
    background-color: #b1b7c1;
}

.img_cont_msg {
    height: 40px;
    width: 40px;
}

#chattool #chat .user_img_msg {
    height: 40px;
    width: 40px;
    border: 1.5px solid #f5f6fa;
}

.rounded-circle {
    border-radius: 50% !important;
    width: 40px;
}

.div-1 {
    display: flex;
    flex-direction: column;

}

.div-2 {
    display: flex;
    flex-direction: row;
}

.mb {
    margin-bottom: 1rem;
}

.green {
    color: #2eb85c;
}

.overlay {
    position: absolute;
    text-align: center;
    bottom: 0;
    background: rgb(0, 0, 0);
    /* Fallback color */
    background: rgba(0, 0, 0, 0.5);
    /* Black background with 0.5 opacity */
    color: #f1f1f1;
    width: 100%;
    height: 30%;
    // padding: 20px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: lightgrey;
}

.reactSwipeScroll {
    overflow: scroll;
    height: calc(100vh - 96px);
}

.infinite-scroll {
    height: 71vh;
    // overflow: auto;
    overflow-y: scroll;
}

.infinite-scroll::-webkit-scrollbar {
    display: none;
}

/* Small screen / tablet / 760px to 991px */
@media (max-width: 991px) {
    .mobile {
        order: 1
    }

    .noPadLeftRight-mobile {
        // padding-left: 0;
        // padding-right: 0;
        padding: 0;
    }

    .infinite-scroll {
        height: 70vh !important;
        display: flex;
        flex-direction: column-reverse;
    }

    .mobile-invisible {
        display: none;
    }

    .actionBubble {
        position: fixed !important;
        top: 70px;
        left: 50px;
        z-index: 999;
    }
}

.gift-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    // padding: 20px 10px 40px;
}

.giftbox {
    width: 45%;
    clear: both;
    margin-bottom: 25px;
    margin-top: 0;
    padding: 0;
}

.giftbox:hover,
.giftbox.active {
    border: 1px solid transparent;
    -webkit-box-shadow: 0 3px 7px 0 #a8a8a8;
    -moz-box-shadow: 0 3px 7px 0 #a8a8a8;
    box-shadow: 0 3px 7px 0 #a8a8a8;
}

.giftbox-content {
    padding: 0;
    border: 1px solid #e7eaec;
}

.giftbox-img-container {
    height: 100px;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
}

.gift-description {
    padding: 20px;
    position: relative;
}

.gift-description h5 {
    text-align: center;
}

.nc-thumbnail {
    min-height: 100px;
}

.nc-thumbnail-300 {
    min-height: 100px;
    max-height: 300px;
}

.nc-thumbnail-200 {
    min-height: 100px;
    max-height: 200px;
    height: 200px;
}

.nc-thumbnail-50 {
    min-height: 50px;
    max-height: 50px;
    height: 50px;
}

.card-img-overlay.card-img-overlay-compact {
    padding: 0.25rem;
    bottom: inherit;
    left: inherit;
}

/* gender colors */

.bg-male,
.badge-male,
.btn-male {
    background-color: #059ef7;
}

.color-male,
.text-male {
    color: #059ef7 !important;
}

.bg-female,
.badge-female,
.btn-female {
    background-color: #e547a1;
}

.color-female,
.text-female {
    color: #e547a1 !important;
}

.bg-divers,
.badge-divers,
.btn-divers {
    background-color: #2eb85c;
}

.color-divers,
.text-divers {
    color: #2eb85c !important;
}

@media (min-width: 992px) {
    .nc-thumbnail {
        min-height: 200px;
    }

    .nc-thumbnail-300 {
        min-height: 100px;
        max-height: 300px;
    }

    .nc-thumbnail-200 {
        min-height: 100px;
        max-height: 200px;
    }

    .nc-thumbnail-100 {
        min-height: 100px;
        max-height: 100px;
    }
}

.profile-status {
    margin-top: -5px;
}


.c-main {
    padding-top: 1rem;
}

.profileview {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
}

.profileview .card {
    margin-bottom: 0.5rem !important;
}

.c-avatar-container {
    min-height: 128px;
}

.c-avatar-xxl {
    width: 128px;
    height: 128px;
}